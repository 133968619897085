<template>
  <div style="background-color: #ffffff; height: 100%">
    <head-layout :head-title="headTitle" :head-btn-options="headBtnOptions" @head-save="headSave('PREPARE')"
      @head-cancel="headCancel"></head-layout>
    <div class="formContentBox">
      <div class="formMain">

        <el-form ref="addForm" :model="addForm" :rules="rules" label-width="120px">
          <div class="formTopic">基本信息</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="编号">
                <el-input v-model="addForm.serialNumber" :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="会议名称" prop="title">
                <el-input v-model="addForm.title" :disabled="readonly">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="会议时间" prop="meetingTime">
                <el-date-picker v-model="addForm.meetingTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                  placeholder="请选择会议时间" :disabled="readonly"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item label="单位名称" prop="unitName">
                <el-input placeholder="请填写单位名称" v-model="addForm.unitName"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="部门名称" prop="deptName">
                <el-input placeholder="请填写部门名称" suffixIcon="el-icon-search" v-model="addForm.deptName"
                  :disabled="pageDisabled || onlyBaseInfoDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row> -->

          <div class="formTopic" style=" display: flex; justify-content: space-between;">
            <span>参会人员</span>
            <span style="color:red;cursor: pointer;" @click="selectPeople" v-if="!readonly"><i class="el-icon-user"></i>
              请选择参会人员</span>
          </div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="参会人员" prop="participants">
                <el-table :data="addForm.participants" style="width: 100%" border>
                  <el-table-column type="index" label="序号" width="60" align="center"> </el-table-column>
                  <el-table-column prop="userName" label="姓名" width="160" align="center"> </el-table-column>
                  <el-table-column prop="userDeptName" label="单位名称"> </el-table-column>
                  <el-table-column prop="post" label="职务/工种" width="160" align="center">
                    <template slot-scope="scope">
                      <span v-if="scope.row.post">{{ scope.row.post }}</span>
                      <span v-else>暂无工种</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="160" align="center" v-if="!readonly">
                    <template slot-scope="scope">
                      <el-link type="danger" @click="removeParticipant(scope.row)"><i class="el-icon-delete"></i>删除
                      </el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="参会人数" prop="meetingNumber">
                <el-input placeholder="请填写参会人数" v-model="addForm.meetingNumber" :disabled="readonly"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="参会人员照片" prop="filesUser">
                <el-upload :disabled="readonly" :class="{
      uoloadSty: showImg,
      disUoloadSty: noneUploadImgVideo,
      'upload-disabled': readonly
    }" :action="'/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform'" list-type="picture-card"
                  accept=".jpg,.png,.gif,jpeg" placeholder="请上传图片" :file-list="addForm.filesUser" :headers="headers"
                  :on-success="(response, file, fileList) => {
      return uploadSuccess(response, file, fileList, 'filesUser');
    }
      " :on-remove="(file, fileList) => {
      return handleRemove(file, fileList, 'filesUser');
    }
      " :on-preview="handlePreview" :on-exceed="uploadExceed" :on-change="uploadChange"
                  :before-upload="uploadBeImgVideofore">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">
                    图片支持*.jpg/*.png/*.jpeg/*.gif格式,文件大小不超过5M;
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="会议内容" prop="meetingContents">
                <el-input placeholder="请填写会议内容" type="textarea" maxlength="500" v-model="addForm.meetingContents"
                  rows="3" show-word-limit :disabled="readonly"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="会议文件" prop="filesMeetting">
                <el-upload :disabled="readonly"
                  :class="{ uoloadSty: showImg, disUoloadSty: noneDealingReport, 'upload-disabled': readonly }"
                  action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                  :file-list="addForm.filesMeetting" :headers="headers" :on-preview="handleFilePreview" :on-success="(response, file, fileList) => {
      return uploadSuccess(response, file, fileList, 'filesMeetting');
    }" :on-remove="(file, fileList) => { return handleRemove(file, fileList, 'filesMeetting'); }"
                  :on-exceed="uploadExceed" :before-upload="handleBeforeUpload">
                  <el-button size="small"><i class="el-icon-upload2"></i> 上传文件</el-button>

                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
        <UserDeptMultipleDialog @closeDia="deptShow = false" ref="UserDetpDialog" @select-all-data="selectPeopleData"
          :tree-params="treeParams">
        </UserDeptMultipleDialog>
      </el-dialog>
      <el-dialog title="预览" :visible.sync="dialogVisible" append-to-body top="5vh" custom-class="customDialog"
        v-if="dialogVisible">
        {{ uploadType }}
        <img style="width: 100%; height: 100%; object-fit: contain" :src="dialogImageUrl" alt=""
          v-if="uploadType == 'img'" />
        <video style="width: 100%; height: 100%; object-fit: contain" autoplay :src="dialogVideoUrl"
          v-if="uploadType == 'video'" controls="controls"></video>
      </el-dialog>
      <el-dialog v-dialog-drag title="附件预览" :modal="true" :modal-append-to-body="false" :close-on-click-modal="false"
        :visible.sync="showFileDialog" width="70%">
        <iframe ref="fileIframe" :src="fileUrl" frameborder="0" style="width: 100%; height: 500px"></iframe>
        <span slot="footer">
          <el-button size="small" @click="showFileDialog = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";

import { getToken } from "@/util/auth";
import { dateFormat } from "@/util/date";
import * as API from "@/api/beforeClass/beforeClass";
import { mapGetters } from "vuex";
export default {
  components: {
    HeadLayout,
    UserDeptMultipleDialog,
  },
  data() {
    var validateFileUser = (rule, value, callback) => {
      if (!value || value.length == 0) {
        callback(new Error('请上传参会人员照片'));
      } else {
        callback();
      }
    };
    var validateParticipants = (rule, value, callback) => {
      if (!value || value.length == 0) {
        callback(new Error('请选择参会人员'));
      } else {
        callback();
      }
    };

    return {
      headTitle: "",
      formType: "",
      readonly: false,
      addForm: {
        title: "",
        serialNumber: "",
        unitName: "",
        unitCode: "",
        deptName: "",
        deptCode: "",
        userName: "",
        userId: "",
        meetingNumber: 0,
        meetingTime: "",
        meetingType: "班前会",
        meetingContents: "",
        filesUser: [],
        filesMeetting: [],
        status: 0,
        participants: []
      },
      rules: {
        title: [{ required: true, message: "请填写会议名称", trigger: "blur" }],
        meetingTime: [{ required: true, message: "请选择会议时间", trigger: "blur" }],
        meetingContents: [{ required: true, message: "请填写会议内容", trigger: "blur" }],
        filesUser: [{ validator: validateFileUser, message: "请上传参会人员照片", trigger: "blur" }],
        participants: [{ validator: validateParticipants, trigger: "blur" }]
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "", // 预览图片路径
      dialogVideoUrl: "", // 预览视频路径
      uploadType: "img", // 默认是图片 img video 是视频的查看方式
      propsHttp: {
        url: "link",
        name: "name",
        res: "data",
      },
      deptShow: false,
      showFileDialog: false,
      fileUrl: "",
      showImg: true, //默认展示
      noneUploadImgVideo: false, //
      noneUploadFile: false,
      noneDealingReport: false,
      noneReportDocUrl: false,
      dealingReport: [],
      reportDocUrl: [],
      treeParams: {
        parentId: undefined
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    pageDisabled() {
      // 如果查看 或者 提交状态（未执行）则禁用 已执行禁用
      if (this.formType == "view") {
        return true;
      } else {
        return false;
      }
    },
    onlyBaseInfoDisabled() {
      // 执行调查进入此页面 基础信息表单需要被禁用
      if (this.formType == "investigate") {
        return true;
      } else {
        return false;
      }
    },
    headBtnOptions() {
      let result = [];
      if (
        ["add", "edit", "investigate"].includes(this.formType) &&
        !this.pageDisabled
      ) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
      }

      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },

  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    init() {
      console.log("this.userInfo", this.userInfo);
      var userInfo = this.userInfo;
      let type = this.$route.query.type;
      this.formType = type;
      this.readonly = type == "view";
      this.headTitle = "班前会";
      var formId = this.$route.query.id;
      this.formId = formId;
      this.getDetails(formId);
      var company_id = userInfo.company_id;
      if (userInfo.userType != "3" && company_id != -1) {
        this.treeParams.parentId = company_id;
      }
    },

    selectPeople() {
      this.deptShow = true;
    },

    selectPeopleData(data) {
      const { selectAllData, treeDeptId, treeDeptName } = data
      console.log(selectAllData, treeDeptId, treeDeptName);
      let participants = this.addForm.participants;
      selectAllData.forEach(item => {
        var some = participants.some(itm => {
          console.log(itm.id, item.id)
          console.log(itm.userDeptId, item.parentDeptId)
          return itm.userId == item.id && itm.userDeptId == item.parentDeptId
        });
        if (!some) participants.push({
          userName: item.realName,
          userDeptName: item.parentDeptName,
          userId: item.id,
          userDeptId: item.parentDeptId,
          post: item.postName,
          sex: item.sex
        })
      })
      this.addForm.participants = participants;
      this.addForm.meetingNumber = participants.length;
      this.deptShow = false;
    },
    headSave(status) {
      this.saveOrSubmitFor(status);
    },
    // 保存提交改变状态
    saveOrSubmitFor(type) {
      let that = this;
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.saveLoading = true;

          this.addForm.draft = 0;
          if (
            (this.formType == "add" && type == "SUBMIT" && this.formId) ||
            (type == "PREPARE" && this.formId) ||
            this.formType == "edit" ||
            this.formType == "investigate"
          ) {
            this.addForm.id = this.formId;
          }
          const params = JSON.parse(JSON.stringify(this.addForm));
          params.filesUser = JSON.stringify(params.filesUser);
          params.filesMeetting = JSON.stringify(params.filesMeetting);

          API.epeventSubmit(params)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                this.$message.error(msg);
              }
              this.saveLoading = false;
            })
            .catch((err) => { });
        }
      });
    },
    removeParticipant(row) {
      let that = this;
      this.$confirm("确定要移除当前人员吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let participants = that.addForm.participants;
        that.addForm.participants = participants.filter(item => { return item.userId != row.userId });
      });

    },
    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 获取详情
    getDetails(id) {
      let that = this;
      var userInfo = that.userInfo;
      let createUser = userInfo.id;
      API.epeventDetail({ id, createUser }).then((res) => {
        if (res.data.code == 200) {
          const { data } = res.data;
          Object.keys(this.addForm).forEach((key) => {
            if (key == "filesUser" || key == "filesMeetting" || key == "participantUsers") {
              var _value = data[key] ? data[key] : "[]"
              this.addForm[key] = JSON.parse(_value);
            }
            else {
              this.addForm[key] = data[key];
            }
          });
          if (!id) {

            this.addForm.createUserName = this.userInfo.real_name;
            this.addForm.createDeptName = this.userInfo.dept_name;
            this.addForm.meetingTime = dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss');
            delete that.addForm.id;
            var participants = that.addForm.participants;
            participants.forEach(item => {
              delete item.id
            })
          }
          console.log(that.addForm)
        }
      });
    },
    // 上传成功
    uploadSuccess(response, file, fileList, fieldName) {
      if (fieldName == "filesMeetting") {
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            item.response.data.url = item.response.data.link;
            item.response.data.name = item.response.data.originalName;
            return item.response.data;
          } else {
            return item;
          }
        });
        let data = response.data;
        data.url = response.data.link;
        if (this.isJudgeVideo(data)) {
          //视频附件，获取第一帧画面作为 封面展示
          this.getVideoCover(data);
        }
      } else {
        // fieldName 表单中向后端传递的字段名
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            return {
              url: item.response.data.link,
              link: item.response.data.link,
              name: item.response.data.originalName,
              attachId: item.response.data.attachId,
              domain: item.response.data.domain,
            };
          } else {
            return item;
          }
        });
      }


    },

    // 判断文件是否为video
    isJudgeVideo(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "mp4" || fileName == "MP4") {
        //视频附件，获取第一帧画面作为 封面展示
        return true;
      } else {
        return false;
      }
    },
    /**
     * 获取视频第一帧作为回显封面
     */
    getVideoCover(file) {
      let _self = this;
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = file.url; // url地址 url跟 视频流是一样的
      file.url = require("@/assets/images/videoLoading.gif"); //设置默认封面，videoDefCover 为预制的默认封面，不需要可去除或替换成自己的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        _self.$set(file, "url", _videoFirstimgsrc); //重置文件的url为当前截取的封面，用于 el-upload展示
        video.remove();
        canvas.remove();
      };
    },
    // 上传失败回调
    handleError() { },
    // 文件预览
    handlePreview(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "jpg" || fileName == "png" || fileName == "jpeg" || fileName == "gif") {
        this.dialogVisible = true;
        // 如果是视频
        // if (fileName == "mp4" || fileName == "MP4") {
        //   this.uploadType = "video";
        //   this.dialogVideoUrl = file.link;
        // } else {
        //   this.uploadType = "img";
        //   this.dialogImageUrl = file.link;
        // }
        this.uploadType = "img";
        this.dialogImageUrl = file.link;
      }
    },
    // 删除文件
    handleRemove(file, fileList, fieldName) {
      if (fieldName == "files") {
        this.addForm[fieldName] = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      } else {
        this[fieldName] = fileList.map((item) => {
          if (item.response) {
            return item.response.data;
          } else {
            return item;
          }
        });
      }
      // if (fileList.length == 0) {
      //   this.$refs.addForm.validateField("fileList");
      // }
    },
    // 文件上传超出限制回调
    uploadExceed(files, fileList) {
      this.$message.error("超出上传限制文件数量");
    },
    // 上传之前的函数
    handleBeforeUpload(file) {
      // let fileName = file.name;
      // let pos = fileName.lastIndexOf(".");
      // let lastName = fileName.substring(pos, fileName.length).toLowerCase();
      // let arr = [".mp4", ".MP4", ".docx", ".pdf", ".xls", ".xlsx"];
      // const isLt20M = file.size / 1024 / 1024 < 20;
      // if (!isLt20M) {
      //   this.$message.warning("上传文件大小不能超过 20MB!");
      //   return false;
      // }
      // if (!arr.includes(lastName)) {
      //   this.$message.warning("不支持上传此类型文件");
      //   return false;
      // }
    },
    // 图片视频上传前的回调
    uploadBeImgVideofore(file) {
      const isJPG = file.type === "image/jpg" || "image/png";
      let maxNum = 5;
      const isLt2M = file.size / 1024 / 1024 < maxNum;

      if (!isJPG) {
        this.$message.error("图片仅支持*.jpg/*.png格式;");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不超过5M;");
      }
      return isJPG && isLt2M;
    },

    // 文件改变时候修改提示信息
    uploadChange(uploadFile, fileList) {
      if (fileList.length !== 0) {
        this.$refs.addForm.validateField("fileList");
      }
    },
    handleFilePreview(file) {
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileName == "jpg" || fileName == "png" || fileName == "jpeg" || fileName == "gif" || fileName == "mp4" || fileName == "MP4") {
        this.dialogVisible = true;
        // 如果是视频
        if (fileName == "mp4" || fileName == "MP4") {
          this.uploadType = "video";
          this.dialogVideoUrl = file.link;
        } else {
          this.uploadType = "img";
          this.dialogImageUrl = file.link;
        }
      } else {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL +
          "?url=" +
          encodeURIComponent(Base64.encode(file.url));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}

.formTopic {
  // margin: 0 12px;
  margin-bottom: 12px;

  // width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.disUoloadSty {
  ::v-deep .el-upload--picture-card {
    display: none;
  }

  ::v-deep .el-upload__tip {
    display: none;
  }

  ::v-deep .el-upload--text {
    display: none;
  }
}

::v-deep.upload-disabled .el-upload--picture-card {
  display: none !important;
}

::v-deep.upload-disabled .el-upload--text {
  display: none !important;
}
</style>
