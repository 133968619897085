<template>
  <div>
    <head-layout  :head-title="'服务类别'"  :head-btn-options="headBtnOptions" @head-add="addFn" >

    </head-layout>
    <el-container class="new-sino-box">
      <el-header class="new-sino-header">
        <div >
          <el-input placeholder="请输入服务名称" v-model="queryList.dict_code" class="new-sino-input" size="mini" />
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </div>
      </el-header>
      <el-main class="new-sino-main">
        <el-table
          v-tableHe
          size="mini"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          border
          :data="tableData"
          style="width: 100%">
          <el-table-column
            align="center"
            prop="qry_id"
            label="ID"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="class_name"
            label="类别名称"
          >
            <template slot-scope="scope">
              <el-input v-if="scope.row.class_name" v-model="scope.row.edit" placeholder="类别名称"></el-input>
              <span v-else>{{scope.row.class_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                v-if="!scope.row.edit"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button
                size="mini"
                type="text"
                v-if="scope.row.edit"
                @click="updateTypeFn(scope.$index, scope.row)">保存</el-button>
              <el-button
                size="mini"
                type="text"
                v-if="scope.row.edit"
                @click="addImgFn( scope.row)">保存</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          title="图片"
          :visible.sync="dialogVisible"
          width="30%"
          >
          <div style="display: flex">
            <div v-for="(item,index) in imgList" :class = '{"xzClass":item.usefilepath == editItem.img_file}' @click="itemClickFn(item)">
              <el-image
                style="width: 100px; height: 100px"
                :src="item.usefilepath"
                >
              </el-image>
              <i class="el-icon-edit" v-if="item.usefilepath == editItem.img_file"></i>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateClassImgFn">确 定</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {updateType, queryTypeList, updateImgList, updateClassImg} from "@/api/dataAcquisition/DataServices";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      dialogVisible:false,
      editItem:false,
      imgList:[],
      tableData: [],
      loading:false,
      queryList:{
        dict_code:'',
      },
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      queryTypeList().then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.map(item=>item.edit=false)
      });
    },
    addFn(){
      this.tableData.push({
        class_id:'',
        img_file:'',
        class_name:'',
        edit:false
      })
    },
    updateTypeFn(item){
      updateType(item).then((res)=>{
        this.onLoad();
      })
    },
    handleEdit(index,item){
      item.edit=true;
    },
    deleteFn(index,item){
      if (index) this.multipleSelection = [item];
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.dict_id
      })
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delDict(ids).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList.param1 = '';
      this.onLoad()
    },
    addImgFn(item){
      this.dialogVisible = true;
      this.editItem = JSON.parse(JSON.stringify(item));
      this.updateImgListFn()
    },
    updateClassImgFn(){
      updateClassImg(this.editItem).then((res)=>{
        this.onLoad();
      })
    },
    updateImgListFn(){
      updateImgList({
        pageNum:1,
        perPage:100
      }).then((res)=>{
        this.imgList = res.data.data;
        this.dialogVisible = false;
      })
    },
    itemClickFn(item){
      this.editItem.img_file = item.usefilepath;
    }
  },
  created() {
    this.onLoad()
  },
  mounted() {
  },
};
</script>

<style>
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
.xzClass{
  position: relative;
}
.xzClass .el-icon-edit{
  position: absolute;
  top:10px;
  right: 10px;
  color:red;
}


</style>
