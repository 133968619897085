var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#ffffff", height: "100%" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("PREPARE")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "div",
            { staticClass: "formMain" },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c("div", { staticClass: "formTopic" }, [_vm._v("基本信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "编号" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.addForm.serialNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "serialNumber", $$v)
                                  },
                                  expression: "addForm.serialNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "会议名称", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.readonly },
                                model: {
                                  value: _vm.addForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "title", $$v)
                                  },
                                  expression: "addForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "会议时间", prop: "meetingTime" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "请选择会议时间",
                                  disabled: _vm.readonly,
                                },
                                model: {
                                  value: _vm.addForm.meetingTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "meetingTime", $$v)
                                  },
                                  expression: "addForm.meetingTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "formTopic",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("span", [_vm._v("参会人员")]),
                      !_vm.readonly
                        ? _c(
                            "span",
                            {
                              staticStyle: { color: "red", cursor: "pointer" },
                              on: { click: _vm.selectPeople },
                            },
                            [
                              _c("i", { staticClass: "el-icon-user" }),
                              _vm._v("\n            请选择参会人员"),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "参会人员",
                                prop: "participants",
                              },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.addForm.participants,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "index",
                                      label: "序号",
                                      width: "60",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "userName",
                                      label: "姓名",
                                      width: "160",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "userDeptName",
                                      label: "单位名称",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "post",
                                      label: "职务/工种",
                                      width: "160",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.post
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row.post)
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v("暂无工种"),
                                                ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  !_vm.readonly
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          width: "160",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: { type: "danger" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeParticipant(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete",
                                                      }),
                                                      _vm._v(
                                                        "删除\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3834191307
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "参会人数",
                                prop: "meetingNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写参会人数",
                                  disabled: _vm.readonly,
                                },
                                model: {
                                  value: _vm.addForm.meetingNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "meetingNumber", $$v)
                                  },
                                  expression: "addForm.meetingNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "参会人员照片",
                                prop: "filesUser",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: {
                                    uoloadSty: _vm.showImg,
                                    disUoloadSty: _vm.noneUploadImgVideo,
                                    "upload-disabled": _vm.readonly,
                                  },
                                  attrs: {
                                    disabled: _vm.readonly,
                                    action:
                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                    "list-type": "picture-card",
                                    accept: ".jpg,.png,.gif,jpeg",
                                    placeholder: "请上传图片",
                                    "file-list": _vm.addForm.filesUser,
                                    headers: _vm.headers,
                                    "on-success": (
                                      response,
                                      file,
                                      fileList
                                    ) => {
                                      return _vm.uploadSuccess(
                                        response,
                                        file,
                                        fileList,
                                        "filesUser"
                                      )
                                    },
                                    "on-remove": (file, fileList) => {
                                      return _vm.handleRemove(
                                        file,
                                        fileList,
                                        "filesUser"
                                      )
                                    },
                                    "on-preview": _vm.handlePreview,
                                    "on-exceed": _vm.uploadExceed,
                                    "on-change": _vm.uploadChange,
                                    "before-upload": _vm.uploadBeImgVideofore,
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  图片支持*.jpg/*.png/*.jpeg/*.gif格式,文件大小不超过5M;\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会议内容",
                                prop: "meetingContents",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写会议内容",
                                  type: "textarea",
                                  maxlength: "500",
                                  rows: "3",
                                  "show-word-limit": "",
                                  disabled: _vm.readonly,
                                },
                                model: {
                                  value: _vm.addForm.meetingContents,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "meetingContents",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.meetingContents",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会议文件",
                                prop: "filesMeetting",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: {
                                    uoloadSty: _vm.showImg,
                                    disUoloadSty: _vm.noneDealingReport,
                                    "upload-disabled": _vm.readonly,
                                  },
                                  attrs: {
                                    disabled: _vm.readonly,
                                    action:
                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                    "file-list": _vm.addForm.filesMeetting,
                                    headers: _vm.headers,
                                    "on-preview": _vm.handleFilePreview,
                                    "on-success": (
                                      response,
                                      file,
                                      fileList
                                    ) => {
                                      return _vm.uploadSuccess(
                                        response,
                                        file,
                                        fileList,
                                        "filesMeetting"
                                      )
                                    },
                                    "on-remove": (file, fileList) => {
                                      return _vm.handleRemove(
                                        file,
                                        fileList,
                                        "filesMeetting"
                                      )
                                    },
                                    "on-exceed": _vm.uploadExceed,
                                    "before-upload": _vm.handleBeforeUpload,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { size: "small" } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload2",
                                      }),
                                      _vm._v(" 上传文件"),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.deptShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deptShow = $event
                },
              },
            },
            [
              _c("UserDeptMultipleDialog", {
                ref: "UserDetpDialog",
                attrs: { "tree-params": _vm.treeParams },
                on: {
                  closeDia: function ($event) {
                    _vm.deptShow = false
                  },
                  "select-all-data": _vm.selectPeopleData,
                },
              }),
            ],
            1
          ),
          _vm.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "预览",
                    visible: _vm.dialogVisible,
                    "append-to-body": "",
                    top: "5vh",
                    "custom-class": "customDialog",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.uploadType) + "\n      "),
                  _vm.uploadType == "img"
                    ? _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: { src: _vm.dialogImageUrl, alt: "" },
                      })
                    : _vm._e(),
                  _vm.uploadType == "video"
                    ? _c("video", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: {
                          autoplay: "",
                          src: _vm.dialogVideoUrl,
                          controls: "controls",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              attrs: {
                title: "附件预览",
                modal: true,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.showFileDialog,
                width: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showFileDialog = $event
                },
              },
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "500px" },
                attrs: { src: _vm.fileUrl, frameborder: "0" },
              }),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showFileDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }